.Circuit {



  /* background: grey;*/
  /* border-style: solid;*/
}
.elementConteiner{

  height:32px;


}

.innerDash {
  position:relative;
  top: 16px;
  background: black;
  width: 100%;
  height: 2px;
}
.innerDashGlobal {
  position:relative;
  top: 18px;
  background: black;
  width: 100%;
  height: 2px;
}

.innerDashSerie {
  position:relative;
  top: 15px;
  background: black;
  width: 100%;
  height: 2px;
}

.element {
  position: relative;
  background-color:white;
  width: 55px;
  height: 28px;
  margin-left:10px;
  margin-right:10px;


}

.serieConteiner {
  background-color:white;
  display: flex;
  flex-direction: row;
}

.serieItem {

}

.paralelConteiner {
  background-color:white;
  display: flex;
  flex-direction: column;
}

.centerDivCirc {
display: flex;
justify-content: center;
}
