@import "~antd/dist/antd.css";
.modebar-group{
  position:absolute;
  top: 8px;
  right: 51px

}

.title {
  background-color:#fafafa;
  border: 1px solid #d9d9d9;
  color: white;
  height: 100px;

}
H1 {
  font-size: 22px
}
H2 {
  font-size: 20px
}
H3 {
  font-size: 20px
}

H1,H2,H3 {
  margin-top: 10px
}
.ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e8;
}

.ant-layout {
  background: white;
}




.ant-layout-footer {
  background: white;
  border-top: 1px solid #e8e8e8;
  margin: 50px;
}

.panelCollapse {
  padding: 2px;
}


table {
  width: 100%;
}


  @media screen and (max-width: 600px) {

      .ant-table-row>td,
       .ant-table-rthead>th{
         padding: 0px;
        font-size: 0.7rem;

      }

  }

  .ant-carousel .slick-slide {
    text-align: justify;
    overflow: hidden;
  }
  .ant-carousel .slick-dots li.slick-active button {
      background: #1890ff;
    }
    .ant-carousel .slick-dots li button {
    margin-top:20px;
    width: 16px;
    height: 10px;
    color: black;
    background: black;
  }

.katex { font: 2em; }

/* ---------- Flexbox divs ---------------- */

.columnDiv{
  display: flex;
  flex-direction: column;

}
.rowDiv{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rowDivCol{
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1090px) { /* to center the wrapped rows*/
  .rowDivCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.rowDivColMenu{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 745px) { /* to center the wrapped rows*/
  .rowDivColMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}


.centerDiv {
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
}

.rightDiv {
display: flex;
justify-content: flex-end;
align-items: center;
flex-wrap: wrap;
}

.leftDiv {
display: flex;
justify-content: flex;
align-items: center;
flex-wrap: wrap;
}

/* -------------Shake---------------*/

.ion {
 /* Start the shake animation and make the animation last for 0.5 seconds */
 animation: shake 0.5s;

 /* When the animation is finished, start again */
 animation-iteration-count: infinite;
}
.ionB {
 /* Start the shake animation and make the animation last for 0.5 seconds */
 animation: shake 0.6s;

 /* When the animation is finished, start again */
 animation-iteration-count: infinite;
}

.ionC {
 /* Start the shake animation and make the animation last for 0.5 seconds */
 animation: shake 0.7s;

 /* When the animation is finished, start again */
 animation-iteration-count: infinite;
}

@keyframes shake {
 0% { transform: translate(1px, 1px) rotate(0deg); }
 10% { transform: translate(-1px, -2px) rotate(-1deg); }
 20% { transform: translate(-3px, 0px) rotate(1deg); }
 30% { transform: translate(3px, 2px) rotate(0deg); }
 40% { transform: translate(1px, -1px) rotate(1deg); }
 50% { transform: translate(-1px, 2px) rotate(-1deg); }
 60% { transform: translate(-3px, 1px) rotate(0deg); }
 70% { transform: translate(3px, 1px) rotate(-1deg); }
 80% { transform: translate(-1px, -1px) rotate(1deg); }
 90% { transform: translate(1px, 2px) rotate(0deg); }
 100% { transform: translate(1px, -2px) rotate(-1deg); }
}



.preH1 {
  margin: 20px;
  font-size: 35px;
  color: black;
}

.preH2 {
  margin: 20px;
  font-size: 30px;
  color: black;
}
.preH3 {
  margin: 20px;
  font-size: 30px;

}

.preIniUl{
  margin-left: 20px;
  margin-top: 50px;
  font-size:25px

}

.ulPresentation {
  font-size: 20px;
}
